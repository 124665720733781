import React from 'react'
import PragICTSAwsUpgradeThumbnail from '../images/projects/pragicts-aws-online-presence-upgrade.jpg'
import AgcEgnyteImplementationThumbnail from '../images/projects/egnyte-agc-implementation.jpg'
import BiophiliaPlantDBThumbnail from '../images/projects/biophilia-knowledgebase.jpg'
import AslAwsmigrationThumbnail from '../images/projects/asl-thumb.jpg'
import ToolshubEcommerceThumbnail from '../images/projects/toolshub-thumb.jpg'
import GreenWings from '../images/projects/greenwin-thumbnail-896e32a0894fe3da52b138b0e40a5dc3.jpeg'
import PandT from '../images/projects/pandt-thumb-9dd8f24ffffd7df379f07f7d6497bff2.jpeg'

import virtues from '../images/projects/virtue-thumbnail-cafab11370fc2fe870c26e55f02019c4.jpeg'
import shanteas from '../images/projects/shan-teas.png'
import Hingurana from '../images/projects/hingurana-group.png'
import  Bluesky from '../images/projects/blue-skye.png'
import Adylet from '../images/projects/adylet (1).png'
import flareup from '../images/projects/flareup.png'
import octagon from '../images/projects/octagon.png'
import unicorn from '../images/projects/unicorn.png'
import zeiss from '../images/projects/zeiss.png'
import AGC from '../images/projects/agc.png'
import puro from '../images/projects/puro.png'

import { Link } from "gatsby"

const RecentProjects = [
    {
        id: 1,
        company: 'The Concept Store',
        thumbnail: AGC,
        url: 'https://theconceptstore.lk/',
        tags: [
            {
                id: 1,
                title: 'Web Application Platform'
            }
        ]
    },
   
   
    {
        id: 2,
        company: 'Biophilia',
        thumbnail: BiophiliaPlantDBThumbnail,
        url: 'https://biophilia.lk/',
        tags: [
            {
                id: 2,
                title: 'Web Application'
            },
            {
                id: 2,
                title: 'Application Support'
            }
        ]
    },
   
    {
        id: 3,
        company: 'Toolshub',
        url: 'https://toolshub.lk/',
        thumbnail: ToolshubEcommerceThumbnail,
        tags: [
            {
                id: 3,
                title: 'eCommerce'
            },
            {
                id: 3,
                title: 'SEO'
            },
            {
                id: 3,
                title: 'Application Support'
            },
            {
                id:3,
                title: 'Branding'
            }
        ]
    },
    {
        id: 4,
        company: 'Adylet',
        thumbnail: Adylet,
        url: 'https://adylet.com/',
        tags: [
            {
                id: 4,
                title: 'Web Application Platform'
            }
        ]
    },
    {
        id: 5,
        company: 'Shan teas',
        thumbnail: shanteas,
        url: 'https://shanteas.com/',
        tags: [
            {
                id: 5,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 6,
        company: 'Virtue',
        thumbnail: virtues,
        url: 'https://virtue.lk/',
        tags: [
            {
                id: 6,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 7,
        company: 'Puro',
        thumbnail: puro,
        url: 'https://puro.lk/',
        tags: [
            {
                id: 7,
                title: 'Website Development'
            }
        ]
    },
   
]

const HomeRecentProjects = () => {

    return (
        <section className="dark-bg" id="projects">
            <div className="fet_pr-carousel-title">
                <div className="fet_pr-carousel-title-item">
                    <h3>Featured Projects</h3>
                    <p>Few of our project highlights</p>
                    <Link to="/projects" className="btn float-btn flat-btn color-btn mar-top">View Portfolio</Link>
                </div>
            </div>
            <div className="slider-carousel-wrap fl-wrap">
                <div className="fet_pr-carousel cur_carousel-slider-container fl-wrap">
                    {RecentProjects.map(project => (
                        <div key={project.id} className="slick-item">
                            <div className="fet_pr-carousel-box">
                                <div className="fet_pr-carousel-box-media fl-wrap">
                                    <img src={project.thumbnail} className="respimg" alt={project.company} title={project.company} />
                                    <a href={project.thumbnail} className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search"></i></a>
                                </div>
                                <div className="fet_pr-carousel-box-text fl-wrap">
                                    <h3><a href={project.url} target='_blank'>{project.company}</a></h3>
                                    <div className="fet_pr-carousel-cat">
                                        {
                                            project.tags.map(tag => (
                                                <a key={tag.id} href="javascript:;">{tag.title}</a>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="sp-cont sp-arr sp-cont-prev"><i className="fal fa-long-arrow-left"></i></div>
                <div className="sp-cont sp-arr sp-cont-next"><i className="fal fa-long-arrow-right"></i></div>
            </div>
            <div className="fet_pr-carousel-counter"></div>
        </section>
    )
}

export default HomeRecentProjects
