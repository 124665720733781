import React from 'react'

import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'

import eset from '../images/technologies/eset.png'

import aws from '../images/technologies/tech-aws-43bfc090c06f02711452ed165987524a.png'

import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'

import sav from '../images/technologies/acronis.png'


import linux from '../images/technologies/linux.png'
import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import bitdefender from '../images/technologies/bitdefender.png'
import quantam from '../images/technologies/quantam.png'
import strata from '../images/technologies/strata.png'

import vbox from '../images/technologies/vbox.png'
import ansible from '../images/technologies/ansible.png'







import python from '../images/clients/python.png'



import azure from '../images/technologies/azure.png'

import splunk from '../images/technologies/splunk.png'

import nessus from '../images/technologies/nessus.png'



import nmap from '../images/technologies/nmap.png'
import wireshark from '../images/technologies/wireshark.png'
import securecrt from '../images/technologies/securecrt.png'
import metasploit from '../images/technologies/metasploit.png'

import snort from '../images/technologies/snort.png'
import ossec from '../images/technologies/ossec.png'
import securityonion from '../images/technologies/security_onion.png'
import pfsense from '../images/technologies/pfsense.png'
import kismet from '../images/technologies/kismet-web.png'







import burpsuite from '../images/technologies/burpsuite.png'
import cortex from '../images/technologies/cortex.png'
import flipper from '../images/technologies/flipper.png'
import foca from '../images/technologies/foca.png'
import gophish from '../images/technologies/gophish.png'
import openvas from '../images/technologies/openvas.png'
import owasp from '../images/technologies/owasp.png'
import wappalyzer from '../images/technologies/wappalyzer.png'
import wifi_pineapple from '../images/technologies/wifi_pineapple.png'
import zabbix from '../images/technologies/Zabbix.png'
import zap from '../images/technologies/zap.png'
import maltego from '../images/technologies/maltego.png'
import amass from '../images/technologies/amass.png'
import suricata from '../images/technologies/suricata.png'
import kali from '../images/technologies/kali.png'
import modsecurity from '../images/technologies/modsec.png'
import hashcat from '../images/technologies/hatcat.png'
import fail2ban from '../images/technologies/fail2ban.png'
import yara from '../images/technologies/yara.png'
import selinux from '../images/technologies/selinux.png'
import ClamAV from '../images/technologies/calmav.png'
import securityplus from '../images/technologies/securityplus.png'
import gpg from '../images/technologies/gpg.png'
import aircrackng from '../images/technologies/aircrack.png'
import sysinternals_suite from '../images/technologies/sysinternals_suite.png'
import keepass from '../images/technologies/keepass.png'
import tripwire from '../images/technologies/tripwire.png'
import os from '../images/technologies/os.png'
import netcat from '../images/technologies/netcat.png'
import wazuh from '../images/technologies/wazuh.png'
import microsoft_baseline from '../images/technologies/microsoft baseline security analyzer.png'
import alienvault_ossim from '../images/technologies/alienvault.png'
import veracrypt  from '../images/technologies/veracrypt.png'
import hping  from '../images/technologies/hping.png' 
import osquery  from '../images/technologies/osquery.png'
import acunetix  from '../images/technologies/acunetix.png' 
import lynis  from '../images/technologies/lynis.png'
import apparmor  from '../images/technologies/apparmor.png'
import sqlmap  from '../images/technologies/sqlmap.png'
import john_ripper  from '../images/technologies/john the ripper software.png'
import zenmap from '../images/technologies/zenmap.png'
import Ipassword from '../images/technologies/1password.png'
import broids from '../images/technologies/bro_ids.png'
import angryipscanner from '../images/technologies/angryipscanner.png'
import rkhunter from '../images/technologies/rkhunter.png'
import bitwarden from '../images/technologies/bitwarden.png'
import mcafeeesm from '../images/technologies/mcafeeesm.png'

const clients = [
   
    {
        name: 'cisco',
        logo: cisco,
        url: 'https://www.cisco.com/'
    },
    {
        name: 'fortinet',
        logo: fortinet,
        url: 'https://www.fortinet.com/'
    },
 
    {
        name: 'eset',
        logo: eset,
        url: 'https://www.eset.com/lk/'
    },
  
  
    {
        name: 'aws',
        logo: aws,
        url: 'https://aws.amazon.com/'
    },
   
    {
        name: 'prometheus',
        logo: prometheus,
        url: 'https://prometheus.io/'
    },
    {
        name: 'grafana',
        logo: grafana,
        url: 'https://grafana.com/'
    },

    {
        name: 'sav',
        logo: sav,
        url: 'https://www.acronis.com/'
    },
    {
        name: 'bitdefender',
        logo: bitdefender,
        url: 'https://www.bitdefender.com/'
    },
 
    {
        name: 'checkpoint',
        logo: checkpoint,
        url: 'https://www.checkpoint.com/'
    },
    {
        name: 'quantam',
        logo: quantam,
        url: '#'
    },
    {
        name: 'paloalto',
        logo: paloalto,
        url: 'https://www.paloaltonetworks.com/'
    },
    {
        name: 'linux',
        logo: linux,
        url: 'https://www.linux.org/'
    },
    {
        name: 'strata',
        logo: strata,
        url: '#'
    },

    {
        name: 'vbox',
        logo: vbox,
        url: 'https://www.virtualbox.org/'
    },
    {
        name: 'ansible',
        logo: ansible,
        url: 'https://www.ansible.com/'
    },
    {
        name: 'python',
        logo: python,
        url: 'https://www.python.org/'
    },
 
 
    {
        name: 'Azure',
        logo: azure,
        url: 'https://azure.microsoft.com/en-us'
    },
  
    {
        name: 'Splunk',
        logo: splunk,
        url: 'https://www.splunk.com/'
    },
 
    {
        name: 'Nessus',
        logo: nessus,
        url: 'https://www.tenable.com/products/nessus'
    },
 
   
    {
        name: 'Nmap',
        logo: nmap,
        url: 'https://nmap.org/'
    },
    {
        name: 'Wireshark',
        logo: wireshark,
        url: 'https://www.wireshark.org/'
    },
    {
        name: 'SecureCRT',
        logo: securecrt,
        url: 'https://www.vandyke.com/products/securecrt/'
    },
    {
        name: 'Metasploit',
        logo: metasploit,
        url: 'https://www.metasploit.com/'
    },
    {
        name: 'snort',
        logo: snort,
        url: 'https://www.snort.org/'
    },
    {
        name: 'kismet',
        logo: kismet,
        url: 'https://www.kismetwireless.net/'
    },
    {
        name: 'ossec',
        logo: ossec,
        url: 'https://www.ossec.net/'
    },
    {
        name: 'securityonionsolutions',
        logo: securityonion,
        url: 'https://securityonionsolutions.com/'
    },
    {
        name: 'pfsense',
        logo: pfsense,
        url: 'https://www.pfsense.org/'
    },
    {
        name: 'Burpsuite',
        logo: burpsuite,
        url: 'https://portswigger.net/burp'
    },
    {
        name: 'Cortex',
        logo: cortex,
        url: 'https://www.paloaltonetworks.com/cortex'
    },
    {
        name: 'Amass',
        logo: amass,
        url: 'https://www.paloaltonetworks.com/cortex'
    },
    {
        name: 'Maltego',
        logo: maltego,
        url: 'https://www.maltego.com/'
    },
    {
        name: 'Zap',
        logo: zap,
        url: 'https://www.zaproxy.org/'
    },
    {
        name: 'Zabbix',
        logo: zabbix,
        url: 'https://www.zabbix.com/index'
    },
    {
        name: 'Wifi_pineapple',
        logo: wifi_pineapple,
        url: ''
    },
    {
        name: 'Wappalyzer',
        logo: wappalyzer,
        url: 'https://www.wappalyzer.com/'
    },
    {
        name: 'Owasp',
        logo: owasp,
        url: 'https://owasp.org/'
    },
    {
        name: 'Openvas',
        logo: openvas,
        url: 'https://openvas.org/'
    },
    {
        name: 'Gophish',
        logo: gophish,
        url: 'https://getgophish.com/'
    },
    {
        name: 'Foca',
        logo: foca,
        url: ''
    },
    {
        name: 'Flipper',
        logo: flipper,
        url: 'https://flipperzero.one/'
    },
    {
        name: 'Kali',
        logo: kali,
        url: 'hhttps://www.kali.org/'
    },
    {
        name: 'Suricata',
        logo: suricata,
        url: 'https://suricata.io/'
    },
    {
        name: 'Modsecurity',
        logo: modsecurity,
        url: 'https://www.modsecurity.org/'
    },
    {
        name: 'Hashcat',
        logo: hashcat,
        url: 'https://hashcat.net/hashcat/'
    },
    {
        name: 'Hashcat',
        logo: hashcat,
        url: 'https://hashcat.net/hashcat/'
    },
    {
        name: 'Fail2ban',
        logo: fail2ban,
        url: ''
    },
    {
        name: 'Yara',
        logo: yara,
        url: ''
    },
    {
        name: 'SElinux',
        logo: selinux,
        url: ''
    },
    {
        name: 'ClamAV',
        logo: ClamAV,
        url: 'https://www.clamav.net/'
    },
    {
        name: 'securityplus',
        logo: securityplus,
        url: ''
    },
    {
        name: 'GPG',
        logo: gpg,
        url: 'https://gnupg.org/'
    },
    {
        name: 'Aircrack-ng',
        logo: aircrackng,
        url: 'https://www.aircrack-ng.org/'
    },
    {
        name: 'Sysinternals Suite',
        logo: sysinternals_suite,
        url: 'https://learn.microsoft.com/en-us/sysinternals/'
    },
    {
        name: 'Keepass',
        logo: keepass,
        url: 'https://keepass.info/'
    },
    {
        name: 'Tripwire',
        logo: tripwire,
        url: 'https://www.tripwire.com/'
    },
    {
        name: 'OS',
        logo: os,
        url: 'https://ophcrack.sourceforge.io/'
    },
    {
        name: 'Netcat',
        logo: netcat,
        url: 'https://nc110.sourceforge.io/'
    },
    {
        name: 'Wazuh',
        logo: wazuh,
        url: 'https://wazuh.com/'
    },
    {
        name: 'Microsoft baseline security analyzer',
        logo: microsoft_baseline,
        url: ''
    },

    {
        name: 'Microsoft baseline security analyzer',
        logo: microsoft_baseline,
        url: ''
    },
    {
        name: 'Alienvault ossim',
        logo: alienvault_ossim,
        url: ''
    },
    {
        name: 'Veracrypt',
        logo: veracrypt,
        url: 'https://veracrypt.fr/en/Home.html'
    },
    {
        name: 'Hping',
        logo: hping,
        url: ''
    },

    {
        name: 'OSQuery',
        logo: osquery,
        url: 'https://www.osquery.io/'
    },
    {
        name: 'Acunetix',
        logo: acunetix,
        url: ''
    },
    {
        name: 'Lynis',
        logo: lynis,
        url: 'https://cisofy.com/lynis/'
    },
    {
        name: 'Apparmor',
        logo: apparmor,
        url: 'https://apparmor.net/'
    },
    {
        name: 'Sqlmap',
        logo: sqlmap,
        url: 'https://sqlmap.org/'
    },
    {
        name: 'John the Ripper',
        logo: john_ripper,
        url: 'https://www.openwall.com/john/'
    },
    {
        name: 'Zenmap',
        logo: zenmap,
        url: 'https://nmap.org/zenmap/'
    },
    {
        name: '1Passowrd',
        logo: Ipassword,
        url: 'https://1password.com/'
    },
    {
        name: 'Bro IDS',
        logo: broids,
        url: 'https://zeek.org/'
    },
    {
        name: 'Angry ip Scanner',
        logo: angryipscanner,
        url: 'https://angryip.org/'
    },
    {
        name: 'rkhunter',
        logo: rkhunter,
        url: ''
    },
    {
        name: 'bitwarden',
        logo: bitwarden,
        url: 'https://bitwarden.com/'
    },
    {
        name: 'McAfee ESM',
        logo: mcafeeesm,
        url: 'https://www.mcafee.com/fr-ch/consumer-corporate/newsroom/press-releases/press-release.html?news_id=d9df8b95-3ba6-4906-9b93-713aef860127&rfhs=1,1'
    },
    
]




const Technologies = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Technologies<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>TECHNOLOGY <span>CAPABILITIES</span></h2>
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Technologies
